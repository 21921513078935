<template>
  <div class="home">
    <div class="top-bg-view"></div>
    <div class="card-view">
      <img class="vip-icon" src="../assets/vip-icon.png" alt="" />
      <div class="left-view">
        <div class="shop-name">Asia market merignac</div>
        <div class="shop-address">17 avenue John Fitzgerald 33700 Merignac</div>
        <div class="open-btn">Créer votre Carte</div>
      </div>
      <div class="right-view">
        <img class="logo-img" src="../assets/logo.png" alt="" />
      </div>
    </div>
    <div class="form-view">
      <van-form label-align="top" @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="username"
            name="name"
            label="Nom prénom"
            placeholder="Nom prénom"
            :rules="[{ required: true, message: 'Nom prénom' }]"
          />
          <van-field
            v-model="phoneNumber"
            name="reference"
            label="Numéros de téléphone"
            placeholder="Numéros de téléphone"
            :rules="[{ required: true, message: 'Numéros de téléphone' }]"
          />
          <van-field
            v-model="addressEmail"
            name="email"
            label="Adresse email"
            placeholder="Adresse email"
            :rules="[{ required: true, message: 'Adresse email' }]"
          />
          <van-field
            v-model="birthday"
            is-link
            readonly
            name="birthday"
            label="Jour Anniversaire"
            placeholder="Jour Anniversaire"
            :rules="[{ required: false, message: 'Jour Anniversaire' }]"
            @click="showCalendar = true"
          />
          <van-calendar
            v-model:show="showCalendar"
            :default-date="defaultDate"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm"
          />
          <van-field
            v-model="postCode"
            name="postCode"
            label="Adresse Postal"
            placeholder="Adresse Postal"
            :rules="[{ required: false, message: 'Adresse Postal' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block class="submit-btn" native-type="submit"> Submit </van-button>
          <!-- <div class="submit-btn" @click="onSubmit">Submit</div> -->
        </div>
      </van-form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { Locale, showNotify, showDialog } from 'vant'
import frFR from 'vant/es/locale/lang/fr-FR'
Locale.use('fr-FR', frFR)
import axios from 'axios'
const showCalendar = ref(false)
const username = ref('')
const birthday = ref('')
const postCode = ref('')
const phoneNumber = ref('')
const addressEmail = ref('')
const originalDate = ref()
const minDate = new Date(1900, 1, 1)
const maxDate = new Date()
const defaultDate = new Date(1990, 1, 1)

const onSubmit = async (values: any) => {
  console.log('submit', values)
  const submitVal: any = JSON.parse(JSON.stringify(values))
  const date = originalDate.value
  if (date) {
    submitVal['birthday'] = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
  }
  const res = await axios.post('http://82.96.138.87:8090/qrweb/newCustomer', submitVal)
  console.log('res:', res)
  if (res.data.status !== 200) {
    showDialog({ message: res.data.msg, showConfirmButton: true, showCancelButton: false })
  } else {
    showDialog({
      message: 'Félicitations, vous avez réussi à créer votre carte',
      showConfirmButton: false,
      showCancelButton: false
    })
  }
}
const formatDate = (date: any) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
const onConfirm = (value: any) => {
  console.log('value:', value)
  originalDate.value = value
  showCalendar.value = false
  birthday.value = formatDate(value)
  console.log('birthday:', birthday)
}
</script>
<style lang="less" scoped>
.home {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .top-bg-view {
    position: absolute;
    top: -450px;
    left: -25%;
    width: 150%;
    height: 600px;
    border-radius: 700px;
    background-color: #f4d49d;
  }
  .card-view {
    position: relative;
    z-index: 10;
    margin: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px #eaeae8;
    // background-color: #353535;
    background-color: #b62720;
    height: 160px;
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .vip-icon {
      position: absolute;
      width: 90px;
      height: 90px;
      opacity: 0.1;
      left: 10px;
      top: 10px;
    }
    .left-view {
      padding: 10px;
      text-align: left;
      width: 60%;
      .shop-name {
        font-size: 16px;
        font-weight: bold;
        // color: #d79448;
        color: #1f0403;
      }
      .shop-address {
        font-size: 14px;
        // color: #966833;
        color: #1c0908;
        margin-top: 10px;
      }
      .open-btn {
        border-radius: 20px;
        // background-color: #48443a;
        background-color: #f6dbae;
        width: 160px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        margin-top: 20px;
        // color: #d1c7ac;
        color: #b62720;
        // border: 2px solid #605b4d;
        border: 2px solid #b62720;
      }
    }
    .right-view {
      flex: 1;
      .logo-img {
        width: 100%;
        height: 100%;
        display: block;
        // opacity: 0.4;
        object-fit: fill;
      }
    }
  }
  .submit-btn {
    border-radius: 20px;
    // background-color: #48443a;
    background-color: #f4d49d;
    width: 100%;
    height: 40px;
    line-height: 36px;
    text-align: center;
    margin-top: 20px;
    // color: #d1c7ac;
    color: #b62720;
    border: 2px solid #f4d49d;
    font-weight: bold;
  }
}
</style>
